import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from 'slabasic/src/translations/messages';
import store from "@/store/store";
import {getLanguageForI18n} from "slabasic/src/helper/helpers";
Vue.use(VueI18n);

export default new VueI18n({
    locale: getLanguageForI18n(store.getters.getLanguage),
    messages
});


<template>
  <BasicPage>
    <v-container fluid>
      <v-row>
        <v-col align="center" class="pr-2">
          <div class="d-flex menu secondary pa-2 rounded-lg py-3 shadow">
            <v-icon class="mx-2" color="danger" size="medium">
              mdi-account
            </v-icon>
            {{ $t('profile.myAccount') }}
          </div>
        </v-col>
        <v-col align="center" class="pl-2" cols="auto">
          <v-btn
            :loading="loading"
            class="mr-2 primary--text py-6 px-8 font-weight-medium shadow rounded-lg"
            color="secondary"
            large
            @click="save">
            {{ $t('save') }}
          </v-btn>
          <v-btn
            class="mx-2 primary--text text-none py-6 shadow rounded-lg"
            color="secondary"
            large
            @click="$router.back()">
            <span class="fade-weak">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn
            :loading="logoutLoading"
            class="ml-2 danger--text text-none py-6 shadow rounded-lg"
            color="secondary"
            large
            @click="logout">
            {{ $t('profile.signOut') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-form ref="form">
        <v-row class="mt-1">
          <v-col cols="6">
            <div class="px-3 py-3 secondary rounded-lg shadow">
              <div class="pa-4 page-title font-weight-medium">{{ $t('profile.myAccount') }}</div>
              <v-container fluid>
                <v-row>
                  <v-col
                    class="pt-1 pb-0 px-4"
                    cols="12"
                    md="6">
                    <v-text-field
                      v-model="firstName"
                      :label="$t('profile.firstName')"
                      :rules="[validations.required]"
                      outlined
                      required/>
                  </v-col>
                  <v-col
                    class="pt-1 pb-0 px-4"
                    cols="12"
                    md="6">
                    <v-text-field
                      v-model="lastName"
                      :label="$t('profile.lastName')"
                      :rules="[validations.required]"
                      outlined
                      required/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="pt-1 pb-0 px-4"
                    cols="12"
                    md="6">
                    <v-text-field
                      :label="$t('profile.currentEmail')"
                      :rules="[confirmNewEmail? validations.required : true, validations.email]"
                      :value="currentEmail"
                      autocomplete="newEmail"
                      disabled
                      outlined
                      readonly
                      required/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="pt-1 pb-0 px-4"
                    cols="12"
                    md="6">
                    <v-text-field
                      v-model="newEmail"
                      :label="$t('profile.newEmail')"
                      :rules="[confirmNewEmail? validations.required : true, validations.email, emailShouldBeDifferent]"
                      autocomplete="newEmail"
                      outlined
                      required/>
                  </v-col>
                  <v-col
                    class="pt-1 pb-0 px-4"
                    cols="12"
                    md="6">
                    <v-text-field
                      v-model="confirmNewEmail"
                      :label="$t('profile.confirmNewEmail')"
                      :rules="[newEmail?validations.required: true, validations.email, emailConfirmationRule]"
                      outlined
                      required
                      type="email"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="pt-1 pb-0 px-4"
                    cols="12"
                    md="6">
                    <v-select
                      v-model="language"
                      :items="languages"
                      :label="$t('profile.language')"
                      :rules="[validations.required]"
                      append-icon=""
                      item-text="label"
                      item-value="value"
                      outlined/>
                  </v-col>
                  <v-col
                    class="pt-1 pb-0 px-4"
                    cols="12"
                    md="6">
                    <v-select
                      v-model="theme"
                      :items="themes"
                      :label="$t('management.organization.theme')"
                      :rules="[validations.required]"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <div class="px-3 py-3 mt-6 secondary rounded-lg shadow">
              <div class="pa-4 page-title font-weight-medium">{{ $t('authentication.password') }}</div>
              <v-container fluid>
                <v-row>
                  <v-col
                      class="pt-1 pb-0 px-4"
                      cols="12"
                      md="6">
                    <v-text-field
                        v-model="currentPassword"
                        :label="$t('profile.currentPassword')"
                        :rules="[newPassword || confirmNewPassword ? validations.required : true, validations.password]"
                        autocomplete="new-password"
                        outlined
                        required
                        :type="showPassword ? 'text' : 'password'">
                      <template v-slot:append>
                        <v-tooltip  bottom color="primary">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="showPassword = !showPassword"
                                    v-bind="attrs"
                                    v-on="on">{{showPassword ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                          </template>
                          {{showPassword ? $t('tooltips.hidePassword'): $t('tooltips.showPassword') }}
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                      class="pt-1 pb-0 px-4"
                      cols="12"
                      md="6">
                    <v-text-field
                        v-model="newPassword"
                        :type="showNewPassword ? 'text' : 'password'"
                        :label="$t('authentication.newPassword')"
                        :rules="[currentPassword || confirmNewPassword ? validations.required : true, validations.password]"
                        autocomplete="new-password"
                        outlined
                        required>
                      <template v-slot:append>
                        <v-tooltip  bottom color="primary">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="generate"
                                    class="mr-2"
                                    v-bind="attrs"
                                    v-on="on">mdi-lock-reset</v-icon>
                          </template>
                          {{ $t('tooltips.generatePassword') }}
                        </v-tooltip>
                        <v-tooltip  bottom color="primary">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="showNewPassword = !showNewPassword"
                                    v-bind="attrs"
                                    v-on="on">{{showNewPassword ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                          </template>
                          {{showNewPassword ? $t('tooltips.hidePassword'): $t('tooltips.showPassword') }}
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col
                      class="pt-1 pb-0 px-4"
                      cols="12"
                      md="6">
                    <v-text-field
                        v-model="confirmNewPassword"
                        :type="showNewPassword ? 'text' : 'password'"
                        :label="$t('profile.confirmNewPassword')"
                        :rules="[currentPassword || newPassword ? validations.required : true, validations.password, passwordConfirmationRule]"
                        autocomplete="new-password"
                        outlined
                        required>
                      <template v-slot:append>
                        <v-tooltip  bottom color="primary">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="generate"
                                    class="mr-2"
                                    v-bind="attrs"
                                    v-on="on">mdi-lock-reset</v-icon>
                          </template>
                          {{ $t('tooltips.generatePassword') }}
                        </v-tooltip>
                        <v-tooltip  bottom color="primary">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="showNewPassword = !showNewPassword"
                                    v-bind="attrs"
                                    v-on="on">{{showNewPassword ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                          </template>
                          {{showNewPassword ? $t('tooltips.hidePassword'): $t('tooltips.showPassword') }}
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="px-3 py-3 secondary rounded-lg shadow">
              <div class="pa-4 page-title font-weight-medium">{{ $t('profile.notificationPreferences') }}</div>
              <v-container fluid>
                <v-row v-for="notificationPreference in notificationPreferences" :key="notificationPreference.notificationTypeId"
                :class="{'divider': notificationPreference.notificationType === 'Sidebar'}">
                  <v-col
                      class="pt-4 pb-0 px-4"
                      cols="6"
                      md="6">
                    {{ notificationPreference.notificationTypeTranslated }}
                  </v-col>
                  <v-col
                      class="pt-1 pb-0 px-4"
                      cols="6"
                      md="6">
                    <v-select
                        v-model="notificationPreference.severityId"
                        :items="severities"
                        outlined
                        required/>

                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </BasicPage>
</template>

<script>
import BasicPage from "slabasic/src/components/BasicPage/BasicPage";
import validations from "slabasic/src/helper/validations";
import {generatePassword} from "slabasic/src/helper/helpers";
import {SEVERITY_CRITICAL, SEVERITY_INFORMATION, SEVERITY_WARNING} from "slabasic/src/helper/constants";

export default {
  name: "Profile",
  components: {BasicPage},
  data() {
    return {
      validations,
      firstName: '',
      lastName: '',
      currentEmail: '',
      newEmail: '',
      confirmNewEmail: '',
      language: 'en-GB',
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      valid: true,
      theme: this.$store.getters.getTheme,
      loading: false,
      logoutLoading: false,
      showPassword: false,
      showNewPassword: false,
      notificationPreferences: []
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.getUser;
    },
    passwordConfirmationRule() {
      return () => (this.newPassword === this.confirmNewPassword) || this.$t('authentication.passwordsNotMatch');
    },
    emailConfirmationRule() {
      return () => (this.newEmail === this.confirmNewEmail) || this.$t('profile.emailsNotMatch');
    },
    emailShouldBeDifferent() {
      return () => (!this.newEmail || this.currentEmail !== this.newEmail) || this.$t('profile.emailShouldBeDifferent');
    },
    severities() {
      return [
        {
          value: -1,
          text: this.$tc('none')
        },
        {
          value: SEVERITY_CRITICAL,
          text: this.$tc('analytics.critical', 1)
        },
        {
          value: SEVERITY_WARNING,
          text: this.$tc('analytics.warning', 1)
        },
        {
          value: SEVERITY_INFORMATION,
          text: this.$tc('analytics.information', 1)
        },
      ];
    },
    languages() {
      return [
        {
          label: this.$t('profile.english'),
          value: 'en-GB',
        },
        {
          label: this.$t('profile.german'),
          value: 'de-DE',
        },
        {
          label: this.$t('profile.swedish'),
          value: 'sv-SE',
        },
        {
          label: this.$t('profile.french'),
          value: 'fr-FR',
        },
        {
          label: this.$t('profile.spanish'),
          value: 'es-ES',
        },
      ];
    },
    themes() {
      return [
        {
          value: 'light',
          text: this.$t('profile.light')
        },
        {
          value: 'dark',
          text: this.$t('profile.dark')
        },
      ];
    }
  },
  mounted() {
    let url = this.$store.getters.getUrl('myAccount');
    this.axios.get(url).then(({data}) => {
      this.firstName = data.data.firstName;
      this.lastName = data.data.lastName;
      this.currentEmail = data.data.userName;
      this.notificationPreferences = data.data.notificationPreferences;
      this.language = data.data.preferredLanguage || 'en-GB';
    });
    url = this.$store.getters.getUrl('versionNumber');
    this.axios.get(url).then(({data}) => {
      this.$store.dispatch('setBEVersionNumber', data.data.versions.versionNumber);
    });
    this.language = this.$store.getters.getLanguage;
  },
  watch: {
    language(language) {
      this.$store.dispatch('setLanguage', language);
    }
  },
  methods: {
    generatePassword,
    save() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.loading = true;
      const url = this.$store.getters.getUrl('updateAccount');
      let data = {
        firstName: this.firstName,
        lastName: this.lastName,
      };
      if (this.newPassword) {
        data.oldPassword = this.currentPassword;
        data.password = this.newPassword;
        data.confirmPassword = this.confirmNewPassword;
      }

      if (this.newEmail) {
        data.email = this.newEmail;
        data.confirmEmail = this.confirmNewEmail;
      }
      data.notificationPreferences = this.notificationPreferences.map(notificationPreference => ({
        severity: notificationPreference.severityId,
        notificationType: notificationPreference.notificationTypeId
      }));

      data.preferredLanguage = this.language;

      this.axios.put(url, data).then(() => {
        this.$store.dispatch('refreshToken').then(() => {
          this.$store.dispatch('setTheme', this.theme);
          this.$vuetify.theme.dark = this.theme === 'dark';
          window.location.reload();
        });
      }).catch((error) => {
        this.$store.dispatch('addNotification',
          {
            type: 'error',
            message: error.response.data.messages
          });
      }).finally(() => {
        this.loading = false;
      });
    },
    logout() {
      const url = this.$store.getters.getUrl('logout');
      this.logoutLoading = true;
      this.axios.post(url, {
        refreshToken: this.$store.getters.getUser.refreshToken
      }).finally(() => {
        this.$store.dispatch('logout');
        this.$router.push({name: 'login'});
      });
    },
    generate() {
      const password = this.generatePassword();
      this.newPassword = password;
      this.confirmNewPassword = password;
      this.showNewPassword = true;
      this.$clipboard(password);
      this.$store.dispatch('addNotification',
          {
            type: 'success',
            message: this.$t('success.passwordCopied')
          });
    }
  }
};
</script>

<style lang="scss" scoped>
.divider {
  border-top: 1px solid lightgray;
  padding-top: 24px;
}
</style>
import axios from "axios";
import store from "@/store/store";
import router from "@/router";
export const initInterceptors = () => {
    let refreshTokenPromise = null;

    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.config.url.includes('refresh')) {
            if (store.getters.isAuthenticated) {
                store.commit('logout');
                router.push({name: 'login'});
            }

            return Promise.reject(error);
        }
        if (error.response && error.response.status === 401) {
            if (!refreshTokenPromise) {
                refreshTokenPromise = store.dispatch('refreshToken').then(() => {
                    refreshTokenPromise = null;
                    return store.getters.getToken;
                });
            }

            return refreshTokenPromise.then(token => {
                error.config.headers['Authorization'] = 'Bearer ' + token;
                if (!token) {
                    return Promise.reject(error);
                }
                return axios.request(error.config);
            });
        }

        return Promise.reject(error);
    });

    axios.interceptors.request.use(config => {
        const token = store.getters.getToken;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
            config.headers['Content-Type'] = 'application/json';
        }
        config.headers['Accept-Language'] = store.getters.getLanguage;

        return config;
    });

};
